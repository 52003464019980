export enum YourNameResult {
  Exit = 'Exit',
}

export enum ApplyingResult {
  Success = 'Success',
  Error = 'Error',
  NoDebtConsolidation = 'NoDebtConsolidation',
}

export enum CreatingOfferResult {
  Continue = 'Continue',
  ManualReview = 'ManualReview',
  OfferUnavailable = 'OfferUnavailable',
  Error = 'Error',
}

export enum ConfirmLoanResult {
  Continue = 'Continue',
  BackToCard = 'BackToCard',
}

export enum HowItWorksResult {
  Continue = 'Continue',
  BackToCard = 'BackToCard',
}

export enum LoaderResult {
  NoOffer = 'NoOffer',
  Success = 'Success',
  CantGenerateOffer = 'CantGenerateOffer',
}

export enum MakingPaymentResult {
  Success = 'Success',
  Failure = 'Failure',
}

export enum OfferStatusResult {
  BackToCard = 'BackToCard',
  BackToYourFinances = 'BackToYourFinances',
}

export enum PayOffResult {
  Continue = 'Continue',
  ManualReview = 'ManualReview',
  EmailSent = 'EmailSent',
  BackToCard = 'BackToCard',
}

export enum YourContactResult {
  Continue = 'Continue',
  OutOfState = 'OutOfState',
}

export enum YourEmployerResult {
  Continue = 'Continue',
  Rejected = 'Rejected',
}

export enum YourSavingsResult {
  Continue = 'Continue',
  BackToCard = 'BackToCard',
}

export enum VerifyingApplicationResult {
  Continue = 'Continue',
  Rejected = 'Rejected',
}

export enum AlloyKycResult {
  Continue = 'Continue',
  Rejected = 'Rejected',
}

export type FlowNextResult =
  | YourNameResult
  | ApplyingResult
  | CreatingOfferResult
  | ConfirmLoanResult
  | HowItWorksResult
  | LoaderResult
  | MakingPaymentResult
  | OfferStatusResult
  | PayOffResult
  | YourContactResult
  | YourEmployerResult
  | YourSavingsResult
  | VerifyingApplicationResult
  | AlloyKycResult
  | undefined;
